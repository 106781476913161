.report-container {
	height: 87vh;
	width: 100%;
	border-radius: 10px;
}
.report-container iframe {
}

.report-container-2 {
	height: 87vh;
	width: 100%;
	border-radius: 10px;
	overflow: hidden;
}
.report-container-2 iframe {
  border: none;
    visibility: hidden;
}

.spinner {
      position: absolute;
  top: 50%;
  left: 50%;
   width: 11.2px;
   height: 11.2px;
   animation: spinner-o824ag 0.8s infinite linear;
}

.spinner div {
   position: absolute;
   width: 100%;
   height: 100%;
   background: rgba(143, 33, 27, 0.15);
   border-radius: 50%;
   animation: spinner-vse6n7 1s infinite ease;
}

.spinner div:nth-child(1) {
   --rotation: 90;
}

.spinner div:nth-child(2) {
   --rotation: 180;
}

.spinner div:nth-child(3) {
   --rotation: 270;
}

.spinner div:nth-child(4) {
   --rotation: 360;
}

@keyframes spinner-vse6n7 {
   0%, 100% {
      transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
   }

   50% {
      transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
   }
}

@keyframes spinner-o824ag {
   to {
      transform: rotate(360deg);
   }
}