/* SupersetViewer.css */

html, body {
  height: 100%;  /* Ensure full height of the page */
  margin: 0;     /* Remove default margins */
}

.superset-container {
  width: 100%;
  height: 100vh; /* Take up full viewport height */
  position: relative;
  overflow: hidden;
}

.superset-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}
