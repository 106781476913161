@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Black.ttf) format("opentype");
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url(../../fonts/OpenSans/OpenSans-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 900;
  src: local("Open Sans"),
    url(../../fonts/OpenSans/OpenSans-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(../../fonts/Roboto/Roboto-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  src: local("Roboto"),
    url(../../fonts/Roboto/Roboto-Bold.ttf) format("opentype");
}
.container {
  height: "fit-content";
  min-height: 87vh;
  margin-right: 2vh;
  text-align: center;
  position: relative;
  margin-bottom: 2vh;
  background-color: #ffffff;
  margin-top: 2vh;
  border-radius: 10px;
  border: 1px solid #ece6e6;
}

.center {
  margin: 0 auto;
}

.mainLoader {
  z-index: 1000;
  background-color: "#000000";
  opacity: 0.5;
  text-align: "center";
}

.fileBox {
  border: 2px;
  border-style: dashed;
  border-color: rgb(213, 218, 215);
  border-radius: 5px;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fileBox > .box {
  text-align: center;
  font-size: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fileBox > .box-2 {
  height: 4em;
}

.MuiAutocomplete-popper .MuiAutocomplete-option {
  background-color: rgba(242, 242, 242, 1);
}

.appbar-background {
  background-image: url("../../images/176b279804f8e04b536be233b06e2f98.png");
}

.logo {
  width: 144px;
}

.title {
  flex-grow: 1;
  padding-left: 15px;
  margin-top: 33px;
}

.sidebar {
  width: 350px;
}

.tenant-text {
  padding-left: 16px;
  padding-top: 5vh;
  padding-bottom: 16px;
}

.sidebar-title {
  padding-left: 16px;
  padding-top: 2vh;
}

.language {
  margin-right: 70px;
  margin-top: 5px;
}

.user-name {
  padding-left: 10px;
}
